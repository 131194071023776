/**
 * Get scroll position.
 * cross-browser compatible
 *
 * Taken from: https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
 */
export function getScrollPosition(): { x: number; y: number } {
  if (typeof window === 'undefined') {
    return { x: 0, y: 0 }
  }
  const supportPageOffset = window.pageXOffset !== undefined
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'

  const x = supportPageOffset
    ? window.pageXOffset
    : isCSS1Compat
    ? document.documentElement.scrollLeft
    : document.body.scrollLeft
  const y = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop

  return { x, y }
}

export const isBrowser = typeof window !== 'undefined'

export const disableScroll = (): void => {
  const { y } = getScrollPosition()
  const bodyElement = document.body
  bodyElement.dataset.offset = `${y}`
  bodyElement.style.overflow = 'hidden'
  bodyElement.style.position = 'fixed'
  bodyElement.style.top = `-${y}px`
  bodyElement.style.width = '100%'
}

export const enableScroll = (): void => {
  const bodyElement = document.body
  const offset = bodyElement.dataset.offset ?? '0'
  delete bodyElement.dataset.offset

  const scrollPosition = parseInt(offset, 10)
  bodyElement.style.removeProperty('overflow')
  bodyElement.style.removeProperty('position')
  bodyElement.style.removeProperty('top')
  bodyElement.style.removeProperty('width')
  window.scrollTo(0, scrollPosition)
}
