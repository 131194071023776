import { css, Global } from '@emotion/react'

import SamsungOneUi500 from './assets/SamsungOneUI-500.ttf'
import SamsungOneUi700 from './assets/SamsungOneUI-700.ttf'

export default function SamsungOneUI(): JSX.Element {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'SamsungOne UI';
          font-display: block;
          font-style: normal;
          font-weight: 500;
          src: url(${SamsungOneUi500}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne UI';
          font-display: block;
          font-style: normal;
          font-weight: 700;
          src: url(${SamsungOneUi700}) format('truetype');
        }
      `}
    />
  )
}
