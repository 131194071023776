import React, { createContext, useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ExploreLandingStoryType } from '~app/components/pages/ExploreLanding/components/ExploreLandingStories'
import { ISectionType } from '~app/components/pages/ExploreRecommendation/components/ExploreRecommendationStepper/components/StepperContentRecommendationResults/helpers/getRecommendedStorySections'

type ExploreContextProviderProps = {
  children: React.ReactNode
}

type SelectedSmartHomeLevel = 'Basic' | 'Intermediate' | 'Advanced'

export type ExploreContext = {
  exploreStories: ExploreLandingStoryType[]
  setExploreStories: React.Dispatch<
    React.SetStateAction<ExploreLandingStoryType[] | []>
  >
  selectedPartners: string[]
  setSelectedPartners: React.Dispatch<React.SetStateAction<string[] | []>>
  persistPartnerSelections: boolean
  setPersistPartnerSelections: React.Dispatch<React.SetStateAction<boolean>>
  persistRecommendationSelections: boolean
  setPersistRecommendationSelections: React.Dispatch<
    React.SetStateAction<boolean>
  >
  activeRecommendationStep: number
  setActiveRecommendationStep: React.Dispatch<React.SetStateAction<number>>
  selectedKeywords: string[]
  setSelectedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  selectedSmartHomeLevel: SelectedSmartHomeLevel
  setSelectedSmartHomeLevel: React.Dispatch<React.SetStateAction<string>>
  recommendationResults: ISectionType[]
  setRecommendationResults: React.Dispatch<React.SetStateAction<ISectionType[]>>
}

export const ExploreContext = createContext<ExploreContext | null>(null)

export default function ExploreContextProvider({
  children,
}: ExploreContextProviderProps): JSX.Element {
  // Query for Explore Story data to be used in multiple components
  const response = useStaticQuery(
    graphql`
      query allContentfulPage {
        contentfulRawModule(component: { eq: "ExploreLanding::Stories" }) {
          content {
            internal {
              content
            }
          }
        }
      }
    `
  )

  const stringifiedContent =
    response?.contentfulRawModule?.content?.internal?.content
  const stories = stringifiedContent
    ? JSON.parse(stringifiedContent).stories
    : []

  const [exploreStories, setExploreStories] = useState<
    ExploreLandingStoryType[] | []
  >(stories || [])
  const [selectedPartners, setSelectedPartners] = useState<string[]>(['all'])
  const [persistPartnerSelections, setPersistPartnerSelections] =
    useState<boolean>(false)
  const [persistRecommendationSelections, setPersistRecommendationSelections] =
    useState<boolean>(false)

  const [activeRecommendationStep, setActiveRecommendationStep] = useState(0)
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([])
  const [selectedSmartHomeLevel, setSelectedSmartHomeLevel] =
    useState<SelectedSmartHomeLevel>('Basic')
  const [recommendationResults, setRecommendationResults] = useState<
    ISectionType[]
  >([])

  return (
    <ExploreContext.Provider
      value={{
        exploreStories,
        setExploreStories,
        selectedPartners,
        setSelectedPartners,
        persistPartnerSelections,
        setPersistPartnerSelections,
        persistRecommendationSelections,
        setPersistRecommendationSelections,
        activeRecommendationStep,
        setActiveRecommendationStep,
        selectedKeywords,
        setSelectedKeywords,
        selectedSmartHomeLevel,
        setSelectedSmartHomeLevel,
        recommendationResults,
        setRecommendationResults,
      }}
    >
      {children}
    </ExploreContext.Provider>
  )
}

export function useExploreContext(): ExploreContext {
  const context = useContext(ExploreContext)
  if (!context) {
    throw new Error(
      'useExploreContext must be used within a ExploreContextProvider'
    )
  }
  return context
}
