exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-brand-page-tsx": () => import("./../../../src/templates/brand-page.tsx" /* webpackChunkName: "component---src-templates-brand-page-tsx" */),
  "component---src-templates-explore-story-page-tsx": () => import("./../../../src/templates/explore-story-page.tsx" /* webpackChunkName: "component---src-templates-explore-story-page-tsx" */),
  "component---src-templates-newsroom-page-tsx": () => import("./../../../src/templates/newsroom-page.tsx" /* webpackChunkName: "component---src-templates-newsroom-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-press-release-page-tsx": () => import("./../../../src/templates/press-release-page.tsx" /* webpackChunkName: "component---src-templates-press-release-page-tsx" */)
}

