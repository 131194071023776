import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ExploreContextProvider from '~app/contexts/explore-context'
import { SearchProvider } from '~app/components/common/Navbar/components/SearchBar/useSearchContext'
import { ThemeProvider } from '~app/theme'
import { WindowResizeProvider } from '~app/hooks/useWindowResize'

// Create a client
const queryClient = new QueryClient()

const App: React.FC<{ children: React.ReactNode }> = props => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <WindowResizeProvider>
        <SearchProvider>
          <ExploreContextProvider>{props.children}</ExploreContextProvider>
        </SearchProvider>
      </WindowResizeProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

export { App }
