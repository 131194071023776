import { css, Global } from '@emotion/react'

import SamsungOne300 from './assets/SamsungOne-300.ttf'
import SamsungOne400 from './assets/SamsungOne-400.ttf'
import SamsungOne500 from './assets/SamsungOne-500.ttf'
import SamsungOne600 from './assets/SamsungOne-600.ttf'
import SamsungOne700 from './assets/SamsungOne-700.ttf'
import SamsungOne800 from './assets/SamsungOne-800.ttf'

export default function SamsungOne(): JSX.Element {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: 300;
          src: url(${SamsungOne300}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: normal;
          src: url(${SamsungOne400}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: 500;
          src: url(${SamsungOne500}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: 600;
          src: url(${SamsungOne600}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: 700;
          src: url(${SamsungOne700}) format('truetype');
        }
        @font-face {
          font-family: 'SamsungOne';
          font-display: block;
          font-style: normal;
          font-weight: 800;
          src: url(${SamsungOne800}) format('truetype');
        }
      `}
    />
  )
}
