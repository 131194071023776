// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import { App } from './src/templates/app'

// Wraps every page in a component
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => <App>{element}</App>

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  require('intersection-observer')
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
}) => {
  // Disable default scroll restoration behavior for explore pages
  return location.pathname.startsWith('/explore/') ? false : true
}
