import emotionStyled from '@emotion/styled'
import { useTheme as emotionUseTheme } from '@emotion/react'

const colors = Object.freeze({
  STBlack: '#000000',
  // @deprecated
  STGrey00: '#1C1C1C',
  // @deprecated
  STGrey01: '#2C2C2C',
  // @deprecated
  STGrey02: '#AEAEAE',
  // @deprecated
  STGrey03: '#F0F0F0',
  // @deprecated
  STGrey04: '#F8F8F8',
  // @deprecated
  STWhite: '#FFFFFF',
  // @deprecated
  STGradientBlack: 'linear-gradient(90deg, #323232 0%, #6E6E6E 100%)',
  // @deprecated
  STGradientPurple: 'linear-gradient(90deg, #8DA9F1 0%, #C3B8FE 100%)',
  // @deprecated
  STGradientGreen: 'linear-gradient(162deg, #A9E3B5 0%, #E0F9B6 100%)',
  // @deprecated
  STGradientBlue: 'linear-gradient(90deg, #76A0F7 0%, #82BDFC 100%)',
  // @deprecated
  STGradientOrange: 'linear-gradient(90deg, #FFA99C 0%, #FFCF65 100%)',

  // v2 colors
  STBlue: '#3169B2',
  PaleCerulean: '#9EBDDE',
  AzureishWhite: '#CCE5F3',
  Mint: '#C2DED5',
  PacificBlue: '#16B0C0',
  GreenTea: '#DBE9AC',
  Flavescent: '#FAE98C',
  PastelPink: '#DFA29A',

  // Gradient Colors
  GreenToBlue: 'linear-gradient(315deg, #3169B2 0%, #16B0C0 100%)',
  BlueToYellow:
    'linear-gradient(135deg, #3169B2 0%, #16B0C0 47.5%, #FAE98C 100%)',
  YellowToBlue:
    'linear-gradient(136.2deg, #FAE98C 0%, #16B0C0 51.09%, #3169B2 102.19%)',

  // Basic Colors
  White: '#FFFFFF',
  Black: '#000000',
  MobileBlack: '#252525',

  // Gray Scale
  Gray0: '#EFF1F4',
  Gray5: '#F8F9FC',
  Gray10: '#EDEFF4',
  Gray20: '#E1E4EB',
  Gray40: '#CED0D6',
  Gray60: '#A9AEB8',
  Gray80: '#8D9199',
  Gray100: '#4B4D52',

  // Samsung Colors
  // Primary Color
  SkyBlue: '#008DF7',
  SkyBlueDark: '#006BEA',
  SkyBlueLight: '#68AEFF',

  // Secondary Color
  Coral: '#EF3434',
  CoralDark: '#D62E2E',
  CoralLight: '#F36E6E',
  SealBlue: '#00B3E3',
  Orange: '#F66700',
  Teal: '#00838F',

  // Gray Scale
  G0_F7: '#F7F7F7',
  G1_EE: '#EEEEEE',
  G2_DD: '#DDDDDD',
  G3_BB: '#BBBBBB',
  G4_AA: '#AAAAAA',
  G5_8F: '#8F8F8F',
  G6_75: '#757575',
  G7_55: '#555555',
  G8_31: '#313131',
  G9_1C: '#1C1C1C',

  // Neutral Color
  NeutralBlue: '#F5F7FE',
  NeutralBeige: '#F7F5F2',
  NeutralOrange: '#FEF6F3',
  NeutralPink: '#F6F0F5',
})

// react-grid configuration
export const grid = Object.freeze({
  breakpoints: Object.freeze({ xs: 0, sm: 640, md: 920, lg: 1200, xl: 1600 }),
  containerMaxWidths: Object.freeze({
    xs: '90.66666667%', // (325 + gutter) / 375
    sm: 640,
    md: 640,
    lg: 960,
    xl: 1200,
  }),
  columns: 12,
  gutterWidth: 50,
  gutters: Object.freeze({ xs: 15, sm: 40, md: 40, lg: 40, xl: 50 }),
})

const breakpoints = Object.freeze([
  `${grid.breakpoints.sm}px`,
  `${grid.breakpoints.md}px`,
  `${grid.breakpoints.lg}px`,
  `${grid.breakpoints.xl}px`,
])

const mediaQueries = Object.freeze({
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
  xlarge: `@media screen and (min-width: ${breakpoints[3]})`,
})

const space: any = [0, 4, 8, 16, 32] // eslint-disable-line @typescript-eslint/no-explicit-any
space.small = space[1]
space.medium = space[2]
space.large = space[3]

const fonts = {
  heading: 'Samsung Sharp Sans',
  body: 'SamsungOne',
  samsungSharpSans: 'Samsung Sharp Sans',
  samsungOne: 'SamsungOne',
  samsungOneUI: 'SamsungOne UI',
}

export const theme = Object.freeze({
  breakpoints,
  mediaQueries,
  colors,
  fonts,

  // @deprecated use spacing instead
  space: Object.freeze(space),

  spacing: Object.freeze({
    none: 0,
    xs1: 2,
    xs2: 4,
    xs3: 8,
    sm: 16,
    md: 24,
    lg1: 32,
    lg2: 40,
    xl1: 48,
    xl2: 56,
    xl3: 64,
    xl4: 96,
    xl5: 128,
    xl6: 160,
    DefaultSpace: 104,
    TabletContainer: 80,
    NavbarHeight: 64,
  }),
  grid,
})
export type ThemeType = typeof theme

export const styled = emotionStyled

export const useTheme = emotionUseTheme
