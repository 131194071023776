import { useLocation } from '@gatsbyjs/reach-router'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

export type SearchContextValue = {
  term: string
  setTerm: (value: string) => void
  isSearchBarOpen: boolean
  openSearchBar: () => void
  closeSearchBar: () => void
}

export const SearchContext = createContext<SearchContextValue | undefined>(
  undefined
)

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [term, setTerm] = useState('')
  const [isSearchBarOpen, setIsSearchBarOpenOpen] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/search') {
      setIsSearchBarOpenOpen(false)
    }
  }, [location.pathname])

  const openSearchBar = useCallback(() => {
    setIsSearchBarOpenOpen(true)
  }, [setIsSearchBarOpenOpen])

  const closeSearchBar = useCallback(() => {
    setIsSearchBarOpenOpen(false)
  }, [setIsSearchBarOpenOpen])

  return (
    <SearchContext.Provider
      value={{
        openSearchBar,
        closeSearchBar,
        isSearchBarOpen: isSearchBarOpen,
        term,
        setTerm,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = (): SearchContextValue => {
  const context = useContext(SearchContext)

  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchContext')
  }

  return context
}
