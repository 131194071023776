import { Fragment } from 'react'
import SamsungOne from './SamsungOne'
import SamsungOneUI from './SamsungOneUI'
import SamsungSharpSans from './SamsungSharpSans'

export default function FontFaces(): JSX.Element {
  return (
    <Fragment>
      <SamsungOne />
      <SamsungOneUI />
      <SamsungSharpSans />
    </Fragment>
  )
}
