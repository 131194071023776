import { css, Global } from '@emotion/react'

import SamsungSharpSans from './assets/SamsungSharpSans.woff'
import SamsungSharpSansMedium from './assets/SamsungSharpSansMedium.woff'
import SamsungSharpSansBold from './assets/SamsungSharpSansBold.woff'

export default function SamsungOneUI(): JSX.Element {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Samsung Sharp Sans';
          font-display: block;
          font-style: normal;
          font-weight: normal;
          src: url(${SamsungSharpSans}) format('truetype');
        }
        @font-face {
          font-family: 'Samsung Sharp Sans';
          font-display: block;
          font-style: normal;
          font-weight: medium;
          src: url(${SamsungSharpSansMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Samsung Sharp Sans';
          font-display: block;
          font-style: normal;
          font-weight: bold;
          src: url(${SamsungSharpSansBold}) format('truetype');
        }
      `}
    />
  )
}
