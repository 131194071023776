import { ThemeProvider } from '@emotion/react'

import { theme } from '../../config'
import FontFaces from '../FontFaces'
import GlobalStyles from '../GlobalStyles'

const StyleProvider: React.FC<{ children: React.ReactNode }> = props => {
  return (
    <ThemeProvider theme={theme}>
      <FontFaces />
      <GlobalStyles />
      {props.children}
    </ThemeProvider>
  )
}

export default StyleProvider
