import { useEffect, useState, useContext, createContext } from 'react'
import { debounce, map, orderBy } from 'lodash'

import { theme } from '~app/theme'
import { isBrowser } from '~app/utils/dom'

type ValueType = {
  breakpoint: string
  resizing: boolean
  width: number
  height: number
  isMobile: boolean
}

const sortedBreakpoints = orderBy(
  map(theme.grid.breakpoints, (value, key) => ({
    breakpoint: key,
    value,
  })),
  ['value'],
  ['desc']
)

export const getBreakpoint = (width: number): string => {
  const { breakpoint } = sortedBreakpoints.find(
    ({ value }) => width >= value
  ) || {
    breakpoint: 'sm',
    value: 0,
  }

  return breakpoint
}

const defaultValue: ValueType = {
  breakpoint:
    typeof window !== 'undefined' ? getBreakpoint(window.innerWidth) : 'xs',
  resizing: false,
  width: typeof window !== 'undefined' ? window.innerWidth : 0,
  height: typeof window !== 'undefined' ? window.innerHeight : 0,
  isMobile:
    typeof window !== 'undefined'
      ? ['xs', 'sm'].includes(getBreakpoint(window.innerWidth))
      : false,
}

const WindowResizeContext = createContext(defaultValue)

export const WindowResizeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [value, setValue] = useState(defaultValue)

  const updateValue = (resizing: boolean): void => {
    const { innerHeight: height, innerWidth: width } = window
    const isMobile = ['xs', 'sm'].includes(getBreakpoint(window.innerWidth))
    const breakpoint = getBreakpoint(window.innerWidth)
    setValue({
      ...value,
      resizing,
      breakpoint,
      isMobile,
      width,
      height,
    })
  }

  /** Debounce function that it is executed when resize event stop*/
  const onResizeEnd = debounce(() => {
    const resizing = false
    updateValue(resizing)
  }, 300)

  const onResize = (): void => {
    const { resizing } = value
    if (!resizing) {
      updateValue(!resizing)
    }
    onResizeEnd()
  }

  const addMouseClass = () => {
    if (isBrowser) document.body.classList.add('using-mouse')
  }

  const removeMouseClass = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (isBrowser) document.body.classList.remove('using-mouse')
    }
  }
  if (isBrowser) {
    document.body.classList.add('using-mouse')
  }

  /**
   * Handle scroll event
   */
  useEffect(() => {
    updateValue(false)
    window.addEventListener('resize', onResize, { passive: true })
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', addMouseClass)

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', removeMouseClass)
    return () => {
      window.removeEventListener('resize', onResize)
      document.body.removeEventListener('mousedown', addMouseClass)
      document.body.removeEventListener('keydown', removeMouseClass)
    }
  }, [])

  return (
    <WindowResizeContext.Provider value={value}>
      {children}
    </WindowResizeContext.Provider>
  )
}

export const useWindowResize = (): ValueType => useContext(WindowResizeContext)

export default useWindowResize
